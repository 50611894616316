.App{
    width: 100vw;
    height: 100vh;
    background-color: #f4f4f4;
}

body{
    margin: 0;
    padding: 0;
    
}
.Sidebar{
    height: 100%;
    width: 250px;
    background-color: white;
}
.list-group {
    overflow: hidden !important;
    overflow-y: scroll !important;
    padding: 10px !important;
    height: 70vh !important;
}
.Logo{
    height: 50px;
}

.container-fluid  {
    background-color: #f4f4f4;
    padding: 0 150px;
}
.flex{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.ul{
    margin: 10px 0;
}

li{
    list-style: none;
    height: 50px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

header a{
    text-decoration: none;
    color: black;
    padding: 10px 15px;
}

header a:hover{
    color: black;
    padding: 10px 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.button {
    text-decoration: none;
    color: black;
}
.AddMenu{
    height: 100vh;
    background-color: #f4f4f4;
    padding: 10px 30px ;
    text-align: center;
}

.AddMenuContainer{
    margin: 25px 0;
}

.AddProduct{
    height: 100vh;
    background-color: #f4f4f4;
    padding: 40px 30px ;
}

.ProductButton{
    width: 150px;
    background-color: #f4f4f4;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    margin: 15px;
}

.EditRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    margin-bottom: 10px;
}

.EditIcon{
    width: 24px;
    margin-right: 10px;
}

.RoomContainer {
    background-color: #f4f4f4;
    padding: 20px 0 0 0;
}

.RoomButton {
    border: 0px !important;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    width: 100%;
}

.RoomEkleButton {
    border: 0px !important;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-right: 15px;
    background-color: rgb(164, 200, 218) !important;
    cursor: pointer;
}

.RomListScroll {
    overflow: hidden;
    overflow-y: scroll;
    max-height: 400px;
}

.row {
    --bs-gutter-x: 0 !important;
}

.MasaButton {
    border: 0px !important;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 2.5rem 0 !important;
    width: 160px;
    text-align: center;
    margin: 2rem 12px 0;
}

.MasaEkleButton {
    border: 0px !important;
    background-color: rgb(164, 200, 218) !important;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 2.5rem 0 !important;
    width: 160px;
    text-align: center;
    margin: 2rem 12px 0;
    cursor: pointer;
}

.TableContainer {
    flex-wrap: wrap;
}

.HomeLeft {
    background-color: blueviolet;
    -webkit-clip-path: polygon(
        0% 0%,
        100% 0%,
        100% calc(100% - 50px),
        0% 100%
    );
            clip-path: polygon(
        0% 0%,
        100% 0%,
        100% calc(100% - 50px),
        0% 100%
    );
}

.LoginContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: cadetblue;
}

.LoginContent {
    background-color: #fff;
    border-radius: 15px;
    max-width: 600px;
    width: 100%;
    height: auto;
    padding: 40px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
}
